const Email = ({ logo, fullname, role, email, phone, phoneFormatted }) => {
  const template = `
    <div style="background-color: transparent; padding: 30px 15px 0 15px; font-family: Arial, sans-serif;">
      <table cellpadding="0" cellspacing="0">
        <tr>
          <td style="padding: 13px 5px 0 5px; border-top: 1px solid rgba(0, 0, 0, 0.25);">
            <img style="display: block; width: 90px; height: auto;" src="/logo_goandup${logo}.png" alt="Go&Up logo" />
            <img style="display: block; margin: 13px 0; width: 178px; height: auto;" src="/stamp.png" alt="Élue agence de marketing mobile 2023" />
            <p style="margin: 12px 0 0 0; font-size: 13px;">
              <strong>${fullname}</strong> - ${role}
            </p>
            <table style="margin: 10px 0 0 0; font-size: 11px; line-height: 18px !important;">
              <tr>
                <td>
                  <img style="width: 10px; height: 12px;" src="/map.png" alt="Map pin icon" />
                </td>
                <td>
                  <p style="text-decoration: none !important; color: #000000 !important; margin: 0 0 0 8px;">22 Bd Edgar Quinet - 75014 Paris</p>
                </td>
              </tr>
              <tr>
                <td>
                  <img style="width: 8px; height: 12px;" src="/phone.png" alt="Phone icon" />
                </td>
                <td>
                  <a style="text-decoration: none !important; color: #000000 !important; margin: 0 0 0 10px;" href="tel:+33145441060">01 45 44 10 60</a>${phone ? '&nbsp;-&nbsp;<a style="text-decoration: none !important; color: #000000 !important;" href="tel:' + phoneFormatted + '">' + phone +'</a>' : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <img style="width: 10px; height: 7px;" src="/email.png" alt="Email icon" />
                </td>
                <td>
                  <p style="text-decoration: none !important; color: #000000 !important; margin: 0 0 0 8px;">${email}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <img style="width: 10px; height: 10px;" src="/site.png" alt="Website icon" />
                </td>
                <td>
                  <a href="https://www.goandup.fr" style="text-decoration: none !important; color: #000000 !important; margin-left: 8px;">www.goandup.fr</a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>`

  return <div dangerouslySetInnerHTML={{ __html: template }} />
}

export default Email
